import React, { CSSProperties } from "react";

export interface FlexRowProps {
    align?: "center" | "top" | "bottom" | "stretch" | "baseline",
    justify?: React.CSSProperties["justifyContent"],
    nowrap?: boolean,
    wrap?: boolean | string,
    column?: boolean,
    
    style?: CSSProperties,
    className?: string,
    onClick?: () => void,
}

const FlexRow: React.FunctionComponent<FlexRowProps> = function (props) {
    let style: React.CSSProperties = Object.assign({ display: "flex" }, props.style);
    if (props.column) {
        style.flexDirection = "column";
    }
    if (typeof props.justify === "string") {
        style.justifyContent = props.justify;
    }
    if (typeof props.align === "string") {
        let val = [
            { label: "center", value: "center" },
            { label: "top", value: "flex-start" },
            { label: "bottom", value: "flex-end" },
            { label: "stretch", value: "stretch" },
            { label: "baseline", value: "baseline" }
        ].find((v) => (v.label === props.align));
        if (val) {
            style.alignItems = val.value;
        }
    }
    if (props.nowrap || typeof props.wrap === "boolean") {
        style.flexWrap = !props.nowrap && props.wrap ? "wrap" : "nowrap";
    }
    return (
        <div style={style} className={props.className} onClick={props.onClick}>
            {props.children}
        </div>
    )
}

export default FlexRow;
