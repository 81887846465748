import React, { Component } from "react";

import Row from '../../components/common/Row';
import Col from '../../components/common/Col';

import "./ProductHighlight.css";
import { cn } from "../../util";

class Step extends Component {
    onClick = () => {
        this.props.onClick(this.props.index);
    }
    render() {
        const { index, highlight, title, text, children } = this.props;
        return (
            <Row className={"mx-0 step" + (highlight ? " highlight" : "")}>
                <Col className="px-0" xs12 s>
                    <div className="step-icon" onClick={this.onClick}>{index + 1}</div>
                    <div className="step-text" onClick={this.onClick}>
                        <p className="emph">{title}</p>
                        {text}
                    </div>
                </Col>
                <Col align="center" xs12 s className="img d-lg-none">
                    {children}
                </Col>
            </Row>
        );
    }
}

class Highlight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toNext: false,
            toPrev: false,
        };
    }
    componentWillReceiveProps(nextProps) {
        let state = {
            toNext: this.props.index === 1 && nextProps.index > this.props.index,
            toPrev: this.props.index === 1 && nextProps.index < this.props.index,
            fromNext: nextProps.index === 1 && this.props.index > 1,
            fromPrev: nextProps.index === 1 && this.props.index < 1,
            nextToPrev: this.props.index === 2 && nextProps.index === 0,
            prevToNext: this.props.index === 0 && nextProps.index === 2,
        };
        this.setState(state);
    }
    render() {
        let className = "img";
        if (this.state.fromNext) {
            className += " from-next";
        } else if (this.state.fromPrev) {
            className += " from-prev";
        } else if (this.state.toNext) {
            className += " to-next";
        } else if (this.state.toPrev) {
            className += " to-prev";
        } else if (this.state.nextToPrev) {
            className += " next-to-prev";
        } else if (this.state.prevToNext) {
            className += " prev-to-next";
        }
        switch (this.props.index) {
            case 0:
                className += " prev";
                break;
            case 1:
                className += " highlight";
                break;
            case 2:
                className += " next";
                break;
        }
        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }
}

export class VideoHighlight extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.playing) {
            if (!this.props.playing) {
                this.videoRef.current.play();
            }
        } else {
            this.videoRef.current.pause();
        }
    }
    render() {
        return (
            <video ref={this.videoRef} className={this.props.className} playsInline muted loop>
                {this.props.children}
            </video>
        );
    }
}

export const ImageHighlight = function ({ animateSmall, steps, positions }) {
    return (
        <div className={cn("img-highlight", animateSmall && "animate-small")}>
            {steps.map((step, idx) => {
                const Content = step.component;
                return (
                    <Highlight key={idx} index={positions.indexOf(idx)}>
                        <Content key={idx} active={positions.indexOf(idx) === 1} />
                    </Highlight>
                );
            })}
        </div>
    );
}

export class StepTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slots: [ 2, 0, 1 ],
        };
    }
    nextHighlight = () => {
        let slots = this.state.slots.slice(1, 3).concat(this.state.slots.slice(0, 1));
        this.setState({
            slots,
        });
        this.stepTimer = setTimeout(this.nextHighlight, this.props.steps[this.state.slots[1]].timeout || 8000);
    }
    componentDidMount() {
        if (window.innerWidth > 992) {
            this.stepTimer = setTimeout(this.nextHighlight, this.props.steps[this.state.slots[1]].timeout || 8000);
        }
    }
    componentWillUnmount() {
        if (this.stepTimer) {
            clearTimeout(this.stepTimer);
            this.stepTimer = undefined;
        }
    }
    onHighlight = (number) => {
        let previousIdx = this.state.slots.indexOf(number);
        if (previousIdx < 1) { // Prev to current
            let slots = this.state.slots.slice(2, 3).concat(number).concat(this.state.slots.slice(1, 2));
            if (this.stepTimer) {
                clearTimeout(this.stepTimer);
                this.stepTimer = undefined;
            }
            this.setState({
                slots,
            });
        } else if (previousIdx > 1) { // Next to current
            let slots = this.state.slots.slice(1, 3).concat(this.state.slots.slice(0, 1));
            if (this.stepTimer) {
                clearTimeout(this.stepTimer);
                this.stepTimer = undefined;
            }
            this.setState({
                slots,
            });
        }
    }
    render() { 
        return this.props.render({ slots: this.state.slots, onHighlight: this.onHighlight });
    }
}

class ProductHighlight extends Component {
    onRender = ({ slots, onHighlight }) => {
        return (
            <Row className="highlights pl-1 pl-lg-3 pr-lg-1 mb-4">
                <Col className="px-0 pr-lg-2">
                    <div className="steps">
                        {this.props.steps.map((step, idx) => {
                            const Content = step.component;
                            return (
                                <Step
                                    key={idx}
                                    index={idx}
                                    highlight={slots.indexOf(idx) === 1}
                                    onClick={onHighlight}
                                    title={step.title}
                                    text={step.text}
                                >
                                    <Content active={true} />
                                </Step>
                            )
                        })}
                    </div>
                </Col>
                <Col className="d-none d-lg-block">
                    <ImageHighlight
                        steps={this.props.steps}
                        positions={slots}
                        translate={this.props.translate}
                    />
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <StepTimer steps={this.props.steps} render={this.onRender} />
        );
    }
}

export default ProductHighlight;
