import React, { Component, Fragment } from "react";
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Row from '../../components/common/Row';
import Col from '../../components/common/Col';
import FlexRow from '../../components/common/FlexRow';
import FlexCell from '../../components/common/FlexCell';


class FAQ extends Component {
    constructor(props) {
        super(props);
        let questions = props.points.map((qa) => {
            if (Array.isArray(qa.a)) {
                return {
                    q: qa.q,
                    a: <Fragment>
                        {qa.a.map((piece) => (typeof piece === "string" ? piece : (
                            <a key={piece.text} href={piece.href}>{piece.text} </a> 
                        )))}
                    </Fragment>
                };
            }
            return qa;
        });
        this.state = {
            questions,
            opened: new Array(questions.length).fill(false),
        };
    }
    render() {
        return (
            <Row className={this.props.className}>
                <Col md-offset-2 md-8>
                    {this.props.title || this.props.subtitle &&
                        <div className="intro mt-3">
                            <h2 id="faqs">
                                {this.props.title}
                            </h2>
                            {this.props.subtitle && <p className="h2-sub">
                                {this.props.subtitle}
                            </p>}
                        </div>
                    }
                    <ul className="qa-list">
                        {this.state.questions.map((item, i) => (
                            <li key={item.q} onClick={() => {
                                if (window.gtag) {
                                    window.gtag('event', `view-faq-${i}`, {
                                        'event_category' : 'view-faq',
                                        'event_label' : `View FAQ ${item.q}`,
                                    });
                                }
                                this.setState({ opened: this.state.opened.map((o, idx) => (i === idx ? !o : o)) });
                            }}>
                                <FlexRow>
                                    <FlexCell grow={1}>
                                        <h6 className="faq-question">{item.q}</h6>
                                    </FlexCell>
                                    <FlexCell>
                                        <FontAwesomeIcon className="pull-right" icon={this.state.opened[i] ? "caret-up" : "caret-down"} />
                                    </FlexCell>
                                </FlexRow>
                                <Collapse isOpened={this.state.opened[i]}>
                                    <ul>
                                        <li>{item.a}</li>
                                    </ul>
                                </Collapse>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        )
    }
}

export default FAQ;
