import React, { CSSProperties } from "react";

export interface FlexCellProps {
    grow?: number,
    p?: string, // Padding string
    pt?: number, // Padding top
    pr?: number, // Padding right
    pb?: number, // Padding bottom
    pl?: number, // Padding left
    ph?: number, // Padding top & bottom
    ps?: number, // Padding sides
    width?: string | number, // Width
    className?: string,
    style?: CSSProperties,
}

const FlexCell: React.FunctionComponent<FlexCellProps> = function (props) {
    let style: React.CSSProperties = props.style || {};
    if (typeof props.grow === "number") {
        style.flexGrow = props.grow;
    }
    if (typeof props.p === "string" && props.p) { style.padding = props.p; }
    if (typeof props.pt === "number") { style.paddingTop = props.pt + "px"; }
    if (typeof props.pr === "number") { style.paddingRight = props.pr + "px"; }
    if (typeof props.pb === "number") { style.paddingBottom = props.pb + "px"; }
    if (typeof props.pl === "number") { style.paddingLeft = props.pl + "px"; }
    if (typeof props.ph === "number") { style.paddingTop = props.ph + "px"; style.paddingBottom = props.ph + "px"; }
    if (typeof props.ps === "number") { style.paddingRight = props.ps + "px"; style.paddingLeft = props.ps + "px"; }
    if (typeof props.width === "number") {
        style.width = props.width + "px";
    } else if (typeof props.width === "string" && props.width) {
        style.width = props.width;
    }
    return (
        <div className={props.className} style={style}>
            {props.children}
        </div>
    );
}

export default FlexCell;
